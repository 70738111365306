<template lang="pug">
main(v-if='blogPosts.length', data-view)
  blog-post-preview-latest(:latestPost='latestPost')
  blog-post-preview(v-for='post in olderPosts', :key='post.id', :post='post')
</template>

<script>
export default {
  name: 'BlogHome',
  components: {
    BlogPostPreviewLatest: () => import('./components/BlogPostPreviewLatest.vue'),
    BlogPostPreview: () => import('./components/BlogPostPreview.vue')
  },
  data() {
    return {
      blogPosts: []
    }
  },
  metaInfo: {
    title: 'Le blog de blast',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Lisez les articles de Blast'
      }
    ]
  },
  computed: {
    allPostsByDate() {
      return [...this.blogPosts].sort((a, b) => new Date(b.first_publication_date) - new Date(a.first_publication_date))
    },
    latestPost() {
      return this.allPostsByDate[0]
    },
    olderPosts() {
      return this.allPostsByDate.slice(1)
    }
  },
  created() {
    this.getBlogPosts()
  },
  methods: {
    async getBlogPosts() {
      const response = await this.$prismic.client.query(this.$prismic.Predicates.at('document.type', 'blog_post'))
      this.blogPosts = response.results
    }
  }
}
</script>
